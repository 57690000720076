// import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../shared/Button';
// import Logo from '../shared/Logo';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

const Hero = () => {
  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);
  const { user, loading } = useContext(UserContext);

  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  return (
    <div className="pt-24">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        {/* left Col */}
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <h1 className="text-7xl font-bold">{t('shared.appName')}</h1>
          <br />
          <h2 className="mt-5 text-5xl text-primary-700">
            {t('shared.shortDescription')}
          </h2>
          <div className="mt-12">
            {!user && (
              <Button
                onClick={handleLogin}
                isLoading={loading}
                className="mx-auto md:mx-0"
              >
                {t('shared.buttons.login')}
              </Button>
            )}
          </div>
        </div>
        {/* Right Col */}
        <div className="w-full md:w-3/5 py-6 text-center">
          <StaticImage src="../../../static/images/hero.png" alt="HappyCV" />
        </div>
      </div>
    </div>
  );
};

export default memo(Hero);
